<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { APIBookingOwnerCancellationPayload, EnumToPrimitiveUnion } from '@yescapa-dev/ysc-api-js/modern'
import { BOOKING_OWNER_CANCEL_REASONS } from '@yescapa-dev/ysc-api-js/modern'
import type { SubmitAction } from '~/types/commons'
import { YSC_API_BOOKING_OWNER_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  id: number
  stateEnhanced: string
  stateSummary: string | null
  idCamper: number | string
  extensionState?: string | null
  isBookingInsuredByYescapa?: boolean
  canAcceptBooking?: boolean
  canRefuseBooking?: boolean
  canRefuseExtension?: boolean
  alertGuestIsMissingDocuments?: boolean
  alertCamperIsMissingDocuments?: boolean
  review?: {
    id: string
    is_published: boolean
  } | null
  isReviewAllowed?: boolean
  isCancellationAllowed?: boolean
  acceptationExpiredOn?: string
  ownerCancelMainReason?: EnumToPrimitiveUnion<BOOKING_OWNER_CANCEL_REASONS>
  acceptBooking: SubmitAction<{ id: number }>
  refuseExtension: SubmitAction<{ state: string }>
}

const props = withDefaults(defineProps<Props>(), {
  extensionState: null,
  isBookingInsuredByYescapa: false,
  canAcceptBooking: false,
  canRefuseBooking: false,
  canRefuseExtension: false,
  alertGuestIsMissingDocuments: false,
  alertCamperIsMissingDocuments: false,
  review: null,
  isReviewAllowed: false,
  isCancellationAllowed: false,
  acceptationExpiredOn: undefined,
})

const { t } = useI18n()

const headerTexts = computed(() => (
  {
    [Constants.BOOKINGS.STATUS.VALIDATED]: {
      title: t('components.app_booking_actions.booking_status.VALIDATED.title'),
      description: t('components.app_booking_actions.booking_status.VALIDATED.new_request'),
    },
    [Constants.BOOKINGS.STATUS.ACCEPTED]: {
      title: t('components.app_booking_actions.booking_status.ACCEPTED.title'),
      description: t('components.app_booking_actions.booking_status.ACCEPTED.description'),
    },
    [Constants.BOOKINGS.STATUS.ACCEPTED_EXPIRED]: {
      title: t('components.app_booking_actions.booking_status.ACCEPTED_EXP.title'),
      description: null,
    },
    [Constants.BOOKINGS.STATUS.TO_COME_NOT_PAID]: {
      title: t('components.app_booking_actions.booking_status.TO_COME_NOT_PAID.title'),
      description: t('components.app_booking_actions.booking_status.TO_COME_NOT_PAID.description'),
    },
    [Constants.BOOKINGS.STATUS.TO_COME]: {
      title: t('components.app_booking_actions.booking_status.TO_COME.title'),
      description: t('components.app_booking_actions.booking_status.TO_COME.description'),
    },
    [STATUS_ENHANCED.ONGOING]: {
      title: t('components.app_booking_actions.booking_status.ONGOING.title'),
      description: t('components.app_booking_actions.booking_status.ONGOING.description'),
    },
    [STATUS_ENHANCED.FINISHED]: {
      title: t('components.app_booking_actions.booking_status.FINISHED.title'),
      description: t('components.app_booking_actions.booking_status.FINISHED.description'),
    },
    [Constants.BOOKINGS.STATUS.CANCELLED_GUEST]: {
      title: t('components.app_booking_actions.booking_status.CANCELLED_GUEST.title'),
      description: null,
    },
    [Constants.BOOKINGS.STATUS.CANCELLED_STAFF]: {
      title: t('components.app_booking_actions.booking_status.CANCELLED_STAFF.title'),
      description: null,
    },
    [Constants.BOOKINGS.STATUS.CANCELLED_BOTH]: {
      title: t('components.app_booking_actions.booking_status.CANCELLED_BOTH.title'),
      description: null,
    },
    [Constants.BOOKINGS.STATUS.CANCELLED_OWNER]: {
      title: t('components.app_booking_actions.booking_status.CANCELLED_OWNER.title'),
      description: null,
    },
    [Constants.BOOKINGS.STATUS.DONE]: {
      title: t('components.app_booking_actions.booking_status.DONE.title'),
      description: null,
    },
  }[props.stateEnhanced] || {}
))

const extensionTexts = computed(() =>
  props.extensionState
    ? (
        {
          [Constants.EXTENSIONS.STATUS.GUEST_ASKED]: {
            title: t('components.app_booking_actions.extension_status.GUEST_ASK.title'),
            link: t('components.app_booking_actions.extension_status.GUEST_ASK.link'),
          },
          [Constants.EXTENSIONS.STATUS.ACCEPTED]: {
            title: t('components.app_booking_actions.extension_status.ACCEPTED.title'),
            link: t('components.app_booking_actions.extension_status.ACCEPTED.link'),
          },
        }[props.extensionState] || {}
      )
    : {},
)

const flagColor = computed(() => getTravelMainColorState(props.stateSummary))

const { sendingForm, setSendingForm } = useSendingForm()
provide(setSendingFormKey, { setSendingForm })

const { stackSuccess } = useToastsStore()
const onSubmit = async () => {
  await props.acceptBooking({ form: { id: props.id } })
  stackSuccess({
    label: { text: 'components.app_booking_quick_actions.accepted', needTranslation: true },
  })
}

const emits = defineEmits<{
  'refuse-booking': []
}>()

const refuseBooking = () => {
  emits('refuse-booking')
}

const refuseExtension = async () => {
  await props.refuseExtension({ form: { state: Constants.EXTENSIONS.STATUS.CANCELLED_OWNER } })
  stackSuccess({
    label: { text: 'components.app_booking_quick_actions.refused', needTranslation: true },
  })
}

const cancellationOwner = useFeatureFlag('cancellationOwner')
const showCancelOwnerModal = ref(false)
const { $api } = useYscApi()
const { bookingOwner } = storeToRefs(useBookingsOwnerStore())
const onCancelBookingOwner: SubmitAction<APIBookingOwnerCancellationPayload> = async ({ form, formEl } = {}) => {
  const formValue = toValue(form)
  if (!formValue) {
    return
  }
  formEl?.value.clearErrors()
  try {
    bookingOwner.value = await $api.bookings.cancelBooking(props.id, formValue)
  }
  catch (e) {
    if (e instanceof Error) {
      const { $errorManager } = useErrorManager()
      $errorManager({ e, name: YSC_API_BOOKING_OWNER_ERROR, formEl })
    }
    return
  }

  showCancelOwnerModal.value = false
}

const { getBookingOwnerCancelInformation } = useBookingOwnerCancel()
</script>

<template>
  <YscCard>
    <template #title>
      {{ headerTexts.title }}
    </template>

    <template #titleImageBlock>
      <YscIconsFlag
        :class="flagColor"
        class="h-6 w-6"
      />
    </template>

    <p v-if="headerTexts.description">
      {{ headerTexts.description }}
    </p>

    <p
      v-if="acceptationExpiredOn"
      class="mb-4 mt-2 font-semibold"
    >
      {{
        $t('components.app_request_actions.date_expiration_accepted_owner_dynamic', {
          time_string: $d(new Date(acceptationExpiredOn), 'h.numeric m.numeric'),
          date_string: $d(new Date(acceptationExpiredOn), 'Y.numeric M.long D.numeric'),
        })
      }}
    </p>

    <!-- Cancel owner confirmed -->
    <div v-if="stateEnhanced === Constants.BOOKINGS.STATUS.CANCELLED_OWNER && ownerCancelMainReason">
      <i18n-t
        v-if="ownerCancelMainReason !== BOOKING_OWNER_CANCEL_REASONS.REASON_PRICES"
        keypath="components.app_modal_booking_owner_cancel.date_blocked_dynamic"
        tag="p"
        scope="global"
      >
        <template #calendar_link_string>
          <NuxtLink
            :to="{ name: 'd-campers-id-calendar', params: { id: idCamper } }"
            class="link link-primary"
          >
            {{ $t('components.app_modal_booking_owner_cancel.calendar_link_string') }}
          </NuxtLink>
        </template>
      </i18n-t>
      <i18n-t
        v-else
        keypath="components.app_modal_booking_owner_cancel.prices_blocked_dynamic"
        tag="p"
        scope="global"
      >
        <template #page_prices>
          <NuxtLink
            :to="{ name: 'd-campers-id-prices', params: { id: idCamper } }"
            class="link link-primary"
          >
            {{ $t('dashboard.products.id.prices.title') }}
          </NuxtLink>
        </template>
      </i18n-t>
      <AppBookingOwnerProofRequired v-if="getBookingOwnerCancelInformation(ownerCancelMainReason)?.isProofRequired" />
    </div>

    <template v-if="isReviewAllowed && (!review || !review?.is_published)">
      <p
        v-if="!review"
        class="mt-4"
      >
        {{ $t('components.app_booking_actions.push_reviews') }}
      </p>
      <p
        v-else
        class="mt-4"
      >
        {{ $t('components.app_booking_actions.owner_review_sent') }}
      </p>
    </template>

    <YscAlert
      v-if="alertGuestIsMissingDocuments"
      permanent
      class="mt-4"
    >
      <h3 class="font-semibold">
        {{ $t('components.app_booking_actions.missing_documents.title') }}
      </h3>
      <p v-if="isBookingInsuredByYescapa">
        {{ $t('components.app_booking_actions.missing_documents.subtitle') }}
      </p>
    </YscAlert>

    <YscAlert
      v-if="alertCamperIsMissingDocuments"
      variant="danger"
      permanent
      class="mt-4"
    >
      <div class="flex items-center space-x-3">
        <div>
          <YscIconsWarning class="h-5 w-5 text-red-500" />
        </div>

        <div class="space-y-2">
          <i18n-t
            keypath="components.app_booking_actions.product_missing_documents.description_dynamic"
            tag="p"
            scope="global"
          >
            <template #emphasis_string>
              <strong>{{ $t('components.app_booking_actions.product_missing_documents.emphasis') }}</strong>
            </template>
          </i18n-t>

          <NuxtLink
            :to="{ name: 'd-campers-id-documents', params: { id: idCamper } }"
            class="link link-primary"
          >
            {{ $t('commons.actions.add_documents') }}
          </NuxtLink>
        </div>
      </div>
    </YscAlert>

    <YscAlert
      v-if="review && !review.is_published"
      permanent
      class="mt-4"
    >
      {{ $t('components.app_booking_actions.review_to_moderate') }}
    </YscAlert>

    <div
      v-if="isReviewAllowed || !review?.is_published || canAcceptBooking || canRefuseBooking"
      class="mt-2 flex flex-wrap justify-center gap-x-4 gap-y-2 sm:justify-start"
    >
      <template v-if="isReviewAllowed || (review && !review?.is_published)">
        <NuxtLink
          v-if="!review"
          :to="{ name: 'f-booking-id', params: { id } }"
          class="btn btn-primary"
        >
          {{ $t('components.app_review_needed_list.link') }}
        </NuxtLink>
        <NuxtLink
          v-else-if="!review.is_published"
          :to="{
            name: 'f-booking-id',
            params: { id },
            query: {
              id_review: review.id,
            },
          }"
          class="btn btn-primary"
        >
          {{ $t('components.app_review_needed_list.edit') }}
        </NuxtLink>
      </template>
      <YscForm
        v-if="canAcceptBooking"
        :submit-action="onSubmit"
      >
        <YscButtonSubmit
          :loading="sendingForm"
          :disabled="alertCamperIsMissingDocuments"
        >
          {{ $t('components.app_booking_quick_actions.accept') }}
        </YscButtonSubmit>
      </YscForm>

      <button
        v-if="canRefuseBooking"
        type="button"
        :disabled="sendingForm"
        class="btn btn-secondary"
        @click.stop.prevent="refuseBooking"
      >
        {{ $t('components.app_booking_quick_actions.refuse') }}
      </button>
    </div>

    <div
      v-if="extensionState"
      class="mt-8 border-t border-gray-200"
    >
      <p
        v-if="extensionTexts.title"
        class="mt-6 font-semibold"
      >
        {{ extensionTexts.title }}
      </p>

      <div class="mt-2 flex flex-wrap justify-center gap-x-4 gap-y-2 sm:justify-start">
        <NuxtLink
          v-if="extensionTexts.link"
          :to="{ name: 'd-bookings-id-extensions', params: { id } }"
          class="btn btn-primary"
        >
          {{ extensionTexts.link }}
        </NuxtLink>

        <button
          v-if="canRefuseExtension"
          class="btn btn-tertiary"
          @click.stop.prevent="refuseExtension"
        >
          {{ $t('commons.actions.decline') }}
        </button>
      </div>
    </div>

    <NuxtLink
      v-if="bookingOwner?.contract_url"
      :to="bookingOwner.contract_url"
      external
      class="link link-primary"
    >
      {{ $t('components.app_booking_actions.view_contract') }}
    </NuxtLink>

    <template
      v-if="isCancellationAllowed && cancellationOwner"
      #footerBlock
    >
      <div class="border-t">
        <button
          type="button"
          class="space-x-2 text-sm mt-4 text-gray-500 flex items-center"
          @click="showCancelOwnerModal = true"
        >
          {{ $t('components.app_booking_owner_cancel_informations.cancel_booking') }}
          <YscIconsArrowRight class="h-5 w-5 ml-1" />
        </button>
      </div>
    </template>
  </YscCard>
  <Teleport to="body">
    <LazyAppModalBookingOwnerCancel
      v-if="showCancelOwnerModal"
      :submit-action="onCancelBookingOwner"
      @close="showCancelOwnerModal = false"
    />
  </Teleport>
</template>
